import {
  bindable
} from 'aurelia-framework';

import * as Actions from '../cctv-store-manager/actions.js';

import { Store } from 'aurelia-store';

import './cctv-video-controls.scss';

import eventService, { EventsList } from 'services/eventService';

import { TranslationService } from 'services/translationService';



export class CctvVideoControls {
  @bindable activeView;
  @bindable cctvChannels;
  @bindable selectedChannel;
  @bindable deviceFeatures;
  @bindable assetId;


  static inject = [Store, TranslationService];

  constructor(store, _TranslationService) {
    this.translations = _TranslationService;

    this.store = store;
    this.actions = Actions;
    this.onChannelChanged = this.onChannelChanged.bind(this);
    this.disable = false;

    eventService.subscribe(EventsList.SignalrbroadcastSnapshot, this.notificationAlert);

  }

  attached() {
    this.actions.onChannelChangedForLiveStream(this.deviceFeatures.channels[0]);
  }

  detached() {
    eventService.unsubscribe(EventsList.SignalrbroadcastSnapshot, this.notificationAlert);
  }

  notificationAlert = item => {
    if (+this.assetId === +item.id && !item.snapshot.isDeviceConnected) {
      this.disable = true;
      let content = {
        message: this.translations.getCap('device_is_offline'),
        button: {
          label: '',
          action: '',
        }
      }
      this.actions.onGlobalMessage(content);
    }
  }

  onChannelChanged(newVal) {
    this.actions.onChannelChangedForLiveStream(newVal);
    this.actions.isOnline({ active: true, online: true });
  }

  changeView(type) {
    if (type) {
      this.activeView = type;
    }
  }

  changeVideoChannel(channel) {
    this.selectedChannel = channel;
  }
}
