/** @format */

import { bindable } from 'aurelia-framework';
import uiService from 'services/uiService';
import './alert-map.scss';

export class AlertMap {
  @bindable alert;

  constructor() {
    this.mapProvider = uiService.mapProvider;
    this.mapTelemetry = null;

    this.mapTelemetryOptions = {
      mode: 'alert',
      tooltips: false,
      alert: 'overspeed'
    };
    this.onMapReady = this.onMapReady.bind(this);

    this.qMapReady = new Promise(resolve => {
      this.qMapResolve = resolve;
    });

    this.alert = null;
  }

  alertChanged(_alert) {
    if (_alert && _alert.positions) {
      this.mapTelemetryOptions.alert = _alert.type;
      this.qMapReady.then(() => {
        this.mapTelemetry = _alert.telemetry;
      });
    }
  }

  onMapReady() {
    this.qMapResolve();
  }
}
