import { inject, DOM, customAttribute } from 'aurelia-framework';

@customAttribute('cls-active-if')
@inject(DOM.Element)
export class ClsActiveIf {

  constructor(element) {
    this.element = element;
  }
  
  valueChanged(value) {
    let action = !!value || ( value !== "" && value == 0) ? 'add' : 'remove';
    this.element.classList[action]('active');
  }
 }


@customAttribute('cls-invalid-if')
@inject(DOM.Element)
export class ClsInvalidIf {

  constructor(element) {
    this.element = element;
  }
  
  valueChanged(value) {
    let action = value ? 'add' : 'remove';
    this.element.classList[action]('invalid');
  }
 }



@customAttribute('cls-validation-if')
@inject(DOM.Element)
export class ClsValidationIf {

  constructor(element) {
    this.element = element;
  }
  
  valueChanged(errors) {
    if(errors && !errors.length) return;
    
    let name = this.element.getAttribute('name');
    if(name) {
      let hasError = (errors||[]).find(x=> x.propertyName === name);
      let action = hasError ? 'add' : 'remove';
      this.element.classList[action]('invalid');
    }
  }
 }
