import { bindable } from 'aurelia-framework';

export class AccountSettings {

  @bindable settings;
  @bindable isReseller;

  @bindable smsReadonly;
  constructor() {
    this.settings = {};
    this.smsReadonly = false;

    this.mapProviders = {
      google: 'google',
      OSM: 'osm'
    }

    this.smsProviders = {
      Esendex: 'Esendex',
      BulkSMS: 'BulkSMS'
    };

  }

  isResellerChanged(value) {
    this.isReseller = value === 'true' || value === true;
  }
}
