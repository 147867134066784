/** @format */

import {
  bindable
} from 'aurelia-framework';
import './attachment-picker.scss';
export class AttachmentPicker {
  attachments;
  @bindable files;
  @bindable text;
  @bindable onChange

  constructor() {
    this.attachments = [];
    this.onDropTarget = this.onDropTarget.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  attached() {
    this.files = [];
    this.attachments = [];
  }

  detached() {
    this.files = [];
    this.attachments = [];
  }

  filesChanged() {
    this.setFile(this.files);
  }

  onDropTarget(e) {
    let inputFiles = (e.files || []);
    if (inputFiles) {
      let files = [];
      for (var i = 0; i < inputFiles.length; i++) {
        files.push(inputFiles[i]);
      }

      this.setFile(files);
    }
  }

  onDelete(e, index) {
    e.preventDefault();
    e.stopPropagation();

    if (this.attachments[index]) {
      this.attachments = this.attachments.filter((a, i) => i != index);
    }

    if (this.onChange) {
      this.onChange(this.attachments);
    }
  }

  setFile(files) {
    if (!files || !files.length) {
      this.attachments = [];
    } else {
      files.forEach(file => {
        if (file) {
          this.attachments.push(file);
        }
      });
    }

    if (this.onChange) {
      this.onChange(this.attachments);
    }
  }

  imageExtension(file) {
    return (file && file.type.match('image/*')) ? true : false
  } 
}
