import store from './store';
import * as Mutations from './mutations';

function pictureInPictureVideo(payload) {
  store.dispatch(Mutations.pictureInPictureVideo, payload);
}

function onChannelChangedForLiveStream(payload) {
  store.dispatch(Mutations.onChannelChangedForLiveStream, payload);
}

function onGlobalMessage(payload) {
  store.dispatch(Mutations.onGlobalMessage, payload);
}

function refVideo(payload) {
  store.dispatch(Mutations.refVideo, payload);
}

function setSelectedChannel(payload) {
  store.dispatch(Mutations.setSelectedChannel, payload);
}

function setAssetUuid(payload) {
  store.dispatch(Mutations.setAssetUuid, payload);
}

function toggleActions(payload) {
  store.dispatch(Mutations.toggleActions, payload);
}

function stopMedia(payload) {
  store.dispatch(Mutations.stopMedia, payload);
}

function toggleFullscreen(payload) {
  store.dispatch(Mutations.toggleFullscreen, payload);
}

function waitingAlertMessage(payload) {
  store.dispatch(Mutations.waitingAlertMessage, payload);
}

function onOverride(payload) {
  store.dispatch(Mutations.onOverride, payload);
}

function isOnline(payload) {
  store.dispatch(Mutations.isOnline, payload);
}

function pip(payload) {
  store.dispatch(Mutations.pip, payload);
}

function liveStreamDetached(payload) {
  store.dispatch(Mutations.liveStreamDetached, payload);
}


export {
  pictureInPictureVideo,
  onChannelChangedForLiveStream,
  onGlobalMessage,
  refVideo,
  setSelectedChannel,
  setAssetUuid,
  toggleActions,
  stopMedia,
  toggleFullscreen,
  waitingAlertMessage,
  onOverride,
  isOnline,
  pip,
  liveStreamDetached,
};
