import { inject, DOM, customAttribute } from 'aurelia-framework';

@customAttribute('if-visible')
@inject(DOM.Element)
export class IfVisible {

  constructor(element) {
    this.element = element;
    this.element.style.opacity = 0;
  }

  valueChanged(newV) {
    if (newV) {
      this.element.style.visibility = 'visible';
      this.element.style.opacity = 1;
    }
    else {
      this.element.style.opacity = 0;
      this.element.style.visibility = 'hidden';
    }
  }
}
