import store from './store';

// Mutations are merely dispatched actions, 
// the logic that makes changes to your state management store 
// is handled from within here

function pictureInPictureVideo(state, payload) {
  const newState = Object.assign({}, state, { globalPictureInPicture: payload });

  return newState;
}

function onChannelChangedForLiveStream(state, payload) {
  const newState = Object.assign({}, state, { liveStreamChannel: payload });

  return newState;
}

function onGlobalMessage(state, payload) {
  const newState = Object.assign({}, state, { alert: payload });

  return newState;
}

function refVideo(state, payload) {
  const newState = Object.assign({}, state, { video: payload });

  return newState;
}

function setSelectedChannel(state, payload) {
  const newState = Object.assign({}, state, { selectedChannel: payload });

  return newState;
}
function setAssetUuid(state, payload) {
  const newState = Object.assign({}, state, { assetUuid: payload });

  return newState;
}
function toggleActions(state, payload) {
  const newState = Object.assign({}, state, { toggleActions: payload });

  return newState;
}
function stopMedia(state, payload) {
  const newState = Object.assign({}, state, { stopMedia: payload });

  return newState;
}
function toggleFullscreen(state, payload) {
  const newState = Object.assign({}, state, { toggleFullscreen: payload });

  return newState;
}
function waitingAlertMessage(state, payload) {
  const newState = Object.assign({}, state, { waitingAlert: payload });

  return newState;
}
function onOverride(state, payload) {
  const newState = Object.assign({}, state, { override: payload });

  return newState;
}
function isOnline(state, payload) {
  const newState = Object.assign({}, state, { isOnline: payload });

  return newState;
}
function pip(state, payload) {
  const newState = Object.assign({}, state, { pip: payload });

  return newState;
}
function liveStreamDetached(state, payload) {
  const newState = Object.assign({}, state, { liveStreamDetached: payload });

  return newState;
}

store.registerAction('pictureInPictureVideo', pictureInPictureVideo);
store.registerAction('onChannelChangedForLiveStream', onChannelChangedForLiveStream);
store.registerAction('onGlobalMessage', onGlobalMessage);
store.registerAction('refVideo', refVideo);
store.registerAction('setSelectedChannel', setSelectedChannel);
store.registerAction('setAssetUuid', setAssetUuid);
store.registerAction('toggleActions', toggleActions);
store.registerAction('stopMedia', stopMedia);
store.registerAction('toggleFullscreen', toggleFullscreen);
store.registerAction('waitingAlertMessage', waitingAlertMessage);
store.registerAction('onOverride', onOverride);
store.registerAction('isOnline', isOnline);
store.registerAction('pip', pip);
store.registerAction('liveStreamDetached', liveStreamDetached);

export {
  pictureInPictureVideo,
  onChannelChangedForLiveStream,
  onGlobalMessage,
  refVideo,
  setSelectedChannel,
  setAssetUuid,
  toggleActions,
  stopMedia,
  toggleFullscreen,
  waitingAlertMessage,
  onOverride,
  isOnline,
  pip,
  liveStreamDetached
};
