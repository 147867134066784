import { inject, DOM, customAttribute, bindable } from 'aurelia-framework';

@customAttribute('if-visible-anim')
@inject(DOM.Element)
export class IfVisibleAnim {

  @bindable delay;
  @bindable show;

  constructor(element) {
    this.element = element;
    this.element.style.opacity = 0;
    this.element.style.visibility = 'hidden';
    this.element.className += ' no-opacity';
  }


  delayChanged(newV) {
    let trans = `opacity ${+newV / 1000}s ease-in-out`;
    this.element.style['-webkit-transition'] = trans;
    this.element.style['-moz-transition'] = trans;
    this.element.style['-ie-transition'] = trans;
    this.element.style['transition'] = trans;
  }

  showChanged(newV) {
    if (newV) {
      // this.toggleProp('visibility', 'visible');
      this.element.style.visibility = 'visible';
      this.element.style.opacity = 1;
    }
    else {
      this.element.style.opacity = 0;
      this.toggleProp('visibility', 'hidden');
    }
  }

  toggleProp(prop, value) {
    let ms = (!isNaN(this.delay) ? +this.delay : 0);
    setTimeout(() => {
      this.element.style[prop] = value;
    }, Math.min(1000, ms));
  }

}
