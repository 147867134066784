import {
  bindable,
  computedFrom,
  observable,
  inject
} from 'aurelia-framework';
import cctvService from 'services/api/cctvService';

import './cctv-video-player.scss';

import * as Actions from '../cctv-store-manager/actions.js';

import { Store } from 'aurelia-store';

import eventService, { EventsList } from 'services/eventService';

const VISIBLE_CONTROLS_TIME = 5000;
const MAX_PLAYING_TIME = 120000;
export class CctvVideoPlayer {
  @bindable selectedChannel;
  @bindable assetUuid;
  @bindable dateStart;
  @bindable dateEnd;
  @bindable assetId;

  @observable isOnline;

  static inject = [Store];

  constructor(store) {
    this.player = null;
    this.isOnline = false;

    this.videoChannelEl = null;
    this.media = {};
    this.videoControlsEl = null;
    this.videoControlsTimeout = null;
    this.showControls = false;
    this.showInFullScreen = false;
    this.cctvService = cctvService;
    this.disablePip = false;

    this.store = store;
    this.actions = Actions;

    this.timer = null;
    this.errorMessage = {
      message: '',
      button: {
        label: '',
        action: ''
      },
      mediaSettings: null
    };

    this.video = null;
    this.togglePipButton = null;

    this.width = '100%';
    this.height = '100%';

    eventService.subscribe(EventsList.SignalrbroadcastSnapshot, this.notificationAlert);

  }

  attached() {
    this.actions.liveStreamDetached(false);
    this.actions.setAssetUuid(this.assetUuid);
    
    if (!document.pictureInPictureEnabled) {
      this.disablePip = true;
    }

    this.store.state.subscribe((state) => {
      if (state.liveStreamChannel && state.liveStreamChannel.id) {
        this.isOnline = true;
      } else {
        this.selectedChannel = {};
        this.isOnline = false;
      }

      if (state.isOnline?.active) {
        this.isOnline = state.isOnline.online;
      }

      this.videoChannelEl = this.store.state?.video;
      if (this.videoChannelEl && this.videoContainer) {
        this.videoContainer.appendChild(this.videoChannelEl);
        this.videoChannelEl.classList.remove("aurelia-hide");
      }
    });
  }

  detached() {
    eventService.unsubscribe(EventsList.SignalrbroadcastSnapshot, this.notificationAlert);
    this.videoChannelEl.classList.add("aurelia-hide");

    this.actions.setSelectedChannel({});
    this.actions.liveStreamDetached(true);
  }

  notificationAlert = item => {
    if (+this.assetId === +item.id && !item.snapshot.isDeviceConnected) {
      this.actions.setSelectedChannel({});
      this.isOnline = false;
      this.store.state.loading = false;
    }
  }

  @computedFrom('showControls')
  get showControlsClass() {
    return this.showControls ? 'active' : '';
  }

  toggleControls(action) {
    clearTimeout(this.videoControlsTimeout);
    this.showControls = true;
    if (action === 'pause' || action === 'play') {
      this.actions.toggleActions(true);
    }

    this.videoControlsTimeout = setTimeout(() => {
      this.showControls = false;
    }, VISIBLE_CONTROLS_TIME);
  }

  stopMedia() {
    this.actions.stopMedia(true);
  }

  toggleFullscreen() {
    this.actions.toggleFullscreen(true);
  }

  onOverride() {
    this.isOnline = true;
    this.actions.onOverride(true);
  }

  togglePictureInPicture() {
    this.actions.pip(true);
  }
}
