/** @format */

import { inject, DOM, customAttribute } from 'aurelia-framework';

@customAttribute('blob-bgimage')
@inject(DOM.Element)
export class BlobBgImage {
  constructor(element) {
    this.element = element;
    this.objectUrl = null;
  }

  valueChanged(value) {
    this.disposeObjectUrl();

    let url = null;

    if (typeof value === 'string') {
      url = value;
    }

    if (value instanceof Blob) {
      url = URL.createObjectURL(value);
    }

    if (url) {
      this.element.style.backgroundImage = `url(${url})`;
    }
  }

  unbind() {
    this.disposeObjectUrl();
  }

  disposeObjectUrl() {
    this.element.style.background = '';

    if (this.objectUrl) {
      URL.revokeObjectURL(this.objectUrl);
      this.objectUrl = null;
    }
  }
}
