/** @format */

import {
  inject,
  customAttribute,
  DOM
} from 'aurelia-framework';

@customAttribute('files-drop-target')
@inject(DOM.Element)
export class FilesDropTarget {
  constructor(element) {
    this.element = element;
    this.onDrop = this.onDrop.bind(this);
    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
  }

  attached() {
    this.element.addEventListener('dragover', this.onDragOver);
    this.element.addEventListener('drop', this.onDrop);

    this.element.addEventListener('dragenter', this.onDragEnter);
    this.element.addEventListener('dragleave', this.onDragLeave);
    this.element.addEventListener('dragend', this.onDragEnd);
  }

  detached() {
    this.element.removeEventListener('dragover', this.onDragOver);
    this.element.removeEventListener('drop', this.onDrop);

    this.element.removeEventListener('dragenter', this.onDragEnter);
    this.element.removeEventListener('dragleave', this.onDragLeave);
    this.element.removeEventListener('dragend', this.onDragEnd);
  }

  onDrop(e) {
    e.stopPropagation();
    e.preventDefault();

    const files = e.dataTransfer.files;
    if (typeof this.value === 'function') {
      this.value({
        files
      });
    } else {
      this.value = files
    }
    this.toggleClass(false);
  }

  onDragEnd(e) {
    e.stopPropagation();
    e.preventDefault();

    e.dataTransfer.clearData();
  }

  onDragOver(e) {
    e.stopPropagation();
    e.preventDefault();

    e.dataTransfer.dropEffect = 'copy';
  }

  onDragEnter(e) {
    this.enterTarget = e.target;
    this.toggleClass(true);
  }

  onDragLeave(e) {
    if (e.target === this.enterTarget) {
      this.toggleClass(false);
    }
  }

  toggleClass(add) {
    this.element.classList[add ? 'add' : 'remove']('dragging');
  }
}
