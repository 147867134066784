import { inject, DOM, customAttribute, bindable } from 'aurelia-framework';

@customAttribute('if-class')
@inject(DOM.Element)
export class IfClass {

  @bindable condition;
  @bindable classes;

  constructor(element) {
    this.element = element;
    this.classes = [];
  }

  conditionChanged(newValue) {
    let action = newValue === true ? 'add' : 'remove';
    this.classes.forEach(c => {
      this.element.classList[action](c.trim());
    });
  }

  classesChanged(newValue) {
    this.classes = (newValue || '').split(',');
  }
 }
