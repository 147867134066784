/** @format */

import {
  computedFrom
} from 'aurelia-framework';
import {
  DialogController
} from 'aurelia-dialog';

import alertsService, {
  AlertConfigs
} from 'services/api/alertsService';
import cctvService from 'services/api/cctvService';
import driversService from 'services/api/driversService';
import assetsService from 'services/api/assetsService';
import { TranslationService } from 'services/translationService';
import moment from 'moment';


import './alert-details.scss';

export class AlertDetails {
  static inject() {
    return [DialogController, TranslationService];
  }
  constructor(_DialogController, _TranslationService) {
    this.dialogController = _DialogController;
    this.translations = _TranslationService;

    //
    this.alertsService = alertsService;
    this.cctvService = cctvService;
    this.driversService = driversService;
    this.assetsService = assetsService;
    //
    this.deviceOfflineLabel = this.translations.getCap('download_start_on_device_online');
    this.mediaHeader = this.translations.getCap('media_attachments');

    this.loading = false;
    this.alert = null;
    this.driver = null;
    this.asset = null;

    this.isDownloadButtonActive = false;
    this.clipDuration = 0;
    this.deviceNames = null;
    this.channelName = 'Channel 1';

    this.closeChecked = false;

    this.cctvChannels = [];
    this.selectedChannel = {}
  }

  @computedFrom('alert.id')
  get requireClose() {
    return this.alert.type === 'panic';
  }

  @computedFrom('loading', 'requireClose', 'closeChecked')
  get disableConfirm() {
    return this.loading || (this.requireClose && !this.closeChecked);
  }

  @computedFrom('alert.type')
  get configType() {
    return AlertConfigs[this.alert.type];
  }

  @computedFrom('configType')
  get hasDetailView() {
    if (this.configType) {
      return (
        ['overspeed', 'poialert', 'zonealert', 'panic'].indexOf(
          this.configType
        ) > -1
      );
    }
  }

  // CHECK IF CCTV FEATURE IS ENABLED
  @computedFrom('asset')
  get deviceCCTVFeature() {
    var result = false;
    if (this.asset && this.asset.deviceFeatures && this.asset.deviceFeatures.length && this.asset.deviceFeatures.find(f => f.id === 'cctv')) {
      result = true;
    }

    return result;
  }

  activate(alert) {
    this.alert = alert;
    this.loadAlert(alert);
  }

  loadAlert(alert) {
    this.loading = true;

    if (alert.driverId && alert.type === 'panic') {
      this.driversService.get(this.alert.driverId).then(d => (this.driver = d));
    }

    this.alertsService.getOccurrences(alert.id).then(_alert => {
      this.alert = _alert;

      this.assetsService.getById(this.alert.assetId).then(_asset => {
        this.asset = _asset;
        const { customData } = this.alert;

        if (customData && customData.mediaId) {
          customData.mediaEnd && customData.mediaStart ? getDurationHandler(customData.mediaEnd, customData.mediaStart) : '';
          this.cctvService.getDevice(this.asset.deviceId).then(device => {
            this.deviceNames = device.metadata;
            if (this.deviceNames) {
              let device = this.deviceNames.channels.find(i => '1' == i.id);
              if (device && device.name && device.name !== '') {
                this.channelName = device.name;
              } else {
                this.channelName = this.translations.getCap('channel ') + 1;
              }
            }
          })

          this.cctvService.getMedia(customData.mediaId).then(response => {
            if (response) {
              response.mediaEnd && response.mediaStart ? getDurationHandler(response.mediaEnd, response.mediaStart) : '';
              if (response.mediaStatus != 'pending') {
                this.isDownloadButtonActive = true;
              } else {
                this.isDownloadButtonActive = false;
              }
            }
          })
        } else {
          this.isDownloadButtonActive = false;
        }
      });

      const getDurationHandler = (dtEnd, dtStart) => {
        this.clipDuration = moment(dtEnd).diff(moment(dtStart), 'seconds');
      };

      this.closeChecked = !!_alert.closed;
      this.loading = false;
    });
  }

  onConfirm() {
    if (!this.alert.comment && this.alert.processed) {
      this.dialogController.ok();
      return;
    }

    this.loading = true;
    this.alert.processed = true;

    this.alertsService
      .updateOccurrence(this.alert)
      .then(data => {
        this.loading = false;
        this.dialogController.ok(data);
      })
      .catch(err => {
        this.error = err.message;;
        this.loading = false;
      });
  }

  onCancel() {
    this.dialogController.cancel();
  }

  getDownload(id) {
    let link = this.cctvService.encodeMediaIdWithToken(id, 'media')
    window.open(link, "_self");
  }
}
