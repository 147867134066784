import { inject, customAttribute, DOM  } from 'aurelia-framework';
import { Router } from 'aurelia-router';

@customAttribute('page-return-esc')
@inject(DOM.Element, Router)
export class PageReturnEscAttr {

  constructor(element, router) {
    this.element = element;
    this.router = router;

    if(this.element) {
      this.element.tabIndex = -1
      this.element.style.outline = 'none'
    }
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  _toggleListener(element, attach) {
    if(!element) return
    element[attach ? 'addEventListener':'removeEventListener']('keydown', this.onKeyDown)
  } 

  attached() {
    this._toggleListener(this.element, true)
  }

  detached() {
    this._toggleListener(this.element, false)
  }

  onKeyDown(e) {
    if(e.keyCode !== 27) return;
    if(!this.router) return;

    if(this.value) {
      this.router.navigateToRoute(this.value)      
      return;
    }
    setTimeout(() => this.router.navigateBack());
  }
}
